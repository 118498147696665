import React, { useState } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { TextField, Button, Paper, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import st from '../../style/auth/signup.module.css';

const SignUp = () => {
  const navigate = useNavigate();
  const lg = console.log;

  const [state, setState] = useState({
    userId: '',
    userPw: '',
    pwConf: '',
    ceoNm: '',
    compNm: '',
    compLoc: '',
    email: '',
    phNo: ''
  });

  const {
    userId,
    userPw,
    pwConf,
    ceoNm,
    compNm,
    compLoc,
    email,
    phNo,
  } = state;

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == 'userId' && value.length > 20) {
      return;
    }

    if (name == 'userPw' && value.length > 20) {
      return;
    }

    if (name == 'pwConf' && value.length > 20) {
      return;
    }

    if (name == 'ceoNm' && value.length > 20) {
      return;
    }

    if (name == 'compNm' && value.length > 20) {
      return;
    }

    if (name == 'compLoc' && value.length > 50) {
      return;
    }

    if (name == 'email' && value.length > 50) {
      return;
    }

    if (name == 'phNo' && value.length > 20) {
      return;
    }



    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validateForm = () => {
    const errMsg = [
      '아이디를 입력하세요',
      '비밀번호를 입력하세요',
      '비밀번호 확인란을 입력하세요',
      '회사명을 입력하세요',
      '대표자명을 입력하세요',
      '회사위치를 입력하세요',
      '이메일을 입력하세요',
      '전화번호를 입력하세요',
    ]

    const formData = [
      userId,
      userPw,
      pwConf,
      compNm,
      ceoNm,
      compLoc,
      email,
      phNo,
    ]

    for (let i in formData) {
      // 전체입력 확인
      if (!formData[i]) {
        // 값이 비었다면
        alert(errMsg[i]);
        return;
      }
    }


    // 비밀번호 일치 확인
    if (userId.length < 3) {
      alert(`아이디는 3글자 이상 입력해야합니다.`);
      return;
    }

    if (userPw.length < 6 || pwConf.length < 6) {
      alert(`비밀번호는 6글자 이상 입력해야합니다.`);
      return;
    }

    if (compNm.length < 2) {
      alert(`회사명은 2글자 이상 입력해야합니다.`);
      return;
    }

    if (ceoNm.length < 2) {
      alert(`대표자명은 2글자 이상 입력해야합니다.`);
      return;
    }

    if (compLoc.length < 2) {
      alert(`회사위치는 2글자 이상 입력해야합니다.`);
      return;
    }

    if (email.length < 5) {
      alert(`이메일주소는 5글자 이상 입력해야합니다.`);
      return;
    }

    if (phNo.length < 8) {
      alert(`전화번호는 8글자 이상 입력해야합니다.`);
      return;
    }

    if (!userPw == pwConf) {
      alert(`비밀번호가 일치하지 않습니다.`);
      return;
    }

    doSignin();
  };

  // 회원가입
  const doSignin = () => {
    const params = {
      userId,
      userPw,
      pwConf,
      compNm,
      ceoNm,
      compLoc,
      email,
      phNo,
    }
    axios.post(`http://${process.env.REACT_APP_API_URL}/auth/signin`, params).then((res) => {
      const resData = res?.data;
      lg('resData == ', resData)
      alert('회원가입 완료');
      navigate('/auth/signin')
    })
      .catch((err) => {
        lg('실패함 ', err.response?.data?.msg)
        alert(err.response?.data?.msg);
      })
  }

  const textFieldStyle = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#1abc9c',
      },
      '&:hover fieldset': {
        borderColor: '#e74c3c',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#ecf0f1',
    },
    '& .MuiOutlinedInput-input': {
      color: '#ecf0f1',
    },
  };

  return (
    <div className={st.mainWrap}>
      <Header />
      <div className={st.banner_content}>
        <div className={st.signup_container}>
          <div className={st.signup_paper}>
            <h2>회원가입</h2>
            <TextField
              label="아이디"
              variant="outlined" a
              fullWidth
              margin="normal"
              value={userId}
              name='userId'
              onChange={handleChange}
              sx={textFieldStyle}
              InputProps={{
                maxLength: 20,
                endAdornment: (
                  <InputAdornment position='end' sx={{ color: 'white' }}>
                    {userId.length}/20
                  </InputAdornment>
                )
              }}
            // helperText={`${userId.length}/10`}
            />
            <TextField
              label="비밀번호"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={userPw}
              name='userPw'
              onChange={handleChange}
              sx={textFieldStyle}
              InputProps={{
                maxLength: 20,
                endAdornment: (
                  <InputAdornment position='end' sx={{ color: 'white' }}>
                    {userPw.length}/20
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label="비밀번호 확인"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={pwConf}
              name='pwConf'
              onChange={handleChange}
              sx={textFieldStyle}
              InputProps={{
                maxLength: 20,
                endAdornment: (
                  <InputAdornment position='end' sx={{ color: 'white' }}>
                    {pwConf.length}/20
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label="회사명"
              variant="outlined"
              fullWidth
              margin="normal"
              value={compNm}
              name='compNm'
              onChange={handleChange}
              sx={textFieldStyle}
              InputProps={{
                maxLength: 20,
                endAdornment: (
                  <InputAdornment position='end' sx={{ color: 'white' }}>
                    {compNm.length}/20
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label="대표자명"
              variant="outlined"
              fullWidth
              margin="normal"
              value={ceoNm}
              name='ceoNm'
              onChange={handleChange}
              sx={textFieldStyle}
              InputProps={{
                maxLength: 20,
                endAdornment: (
                  <InputAdornment position='end' sx={{ color: 'white' }}>
                    {ceoNm.length}/20
                  </InputAdornment>
                )
              }}
            />

            <TextField
              label="회사위치"
              variant="outlined"
              fullWidth
              margin="normal"
              value={compLoc}
              name='compLoc'
              onChange={handleChange}
              sx={textFieldStyle}
              InputProps={{
                maxLength: 20,
                endAdornment: (
                  <InputAdornment position='end' sx={{ color: 'white' }}>
                    {compLoc.length}/50
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label="이메일"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              name='email'
              onChange={handleChange}
              sx={textFieldStyle}
              InputProps={{
                maxLength: 20,
                endAdornment: (
                  <InputAdornment position='end' sx={{ color: 'white' }}>
                    {email.length}/50
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label="전화번호"
              variant="outlined"
              fullWidth
              margin="normal"
              value={phNo}
              name='phNo'
              onChange={handleChange}
              sx={textFieldStyle}
              InputProps={{
                maxLength: 20,
                endAdornment: (
                  <InputAdornment position='end' sx={{ color: 'white' }}>
                    {phNo.length}/20
                  </InputAdornment>
                )
              }}
            />
            <p style={{ color: 'red' }}>* 민감한 개인정보는 절대 입력하지 마세요.</p>
            <div className={st.button_group}>
              <button className={st.submit_button} onClick={validateForm}>
                회원가입
              </button>
              <button
                type="button"
                className={st.cancel_button}
                onClick={() => navigate('/')}
              >
                취소
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SignUp;
