// redux 파일
import { configureStore, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const lg = console.log;

let user = createSlice({
    name: 'user',  // redux 에 등록될 속성명
    initialState: '', // 속성의 실제 값.
    reducers: {
        // redux에 있는 상태변경 함수 등록하는 곳.
        changeName(state) {
            lg('state == ', state)
            return 'john '
        },

        getSessionData() {
            axios.post(`http://${process.env.REACT_APP_API_URL}/auth/checkLogin`, {}, { withCredentials: 'include' }).then((res) => {
                const resData = res.data;
                lg('getSessionData 통신성공 = ', resData)
            })
                .catch((err) => {
                    alert(err.response?.data?.msg)
                })
        }
    }
})

export default configureStore({
    reducer: {
        user: user.reducer
    }
})

export let { changeName, getSessionData } = user.actions 