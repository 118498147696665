import React from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import st from '../../style/intro/intro.module.css';

const Intro = () => {
    const personalInfo = {
        name: '민창준',
        address: '경기도 광명시',
        email: 'mincj93@naver.com',
        phone: '010-3105-2676',
    };

    const education = [
        { school: '충남예술고등학교', major: '무용학과', graduation: '2013년 02월' },
        { school: '한국체육대학교', major: '생활무용학과', graduation: '2020년 02월' },
    ];

    const certificates = [
        { name: '2종보통운전면허', acquired: '2014년' },
    ];

    const skills = [
        'React', 'NodeJS', 'JavaScript', 'Java', 'MYSQL', 'Oracle', 'WebSquare',
        'Spring Framework', 'Express Framework', 'Next.JS', 'Git', 'AWS'
    ];

    const career = [
        // ESP 시스템즈
        {
            company: 'ESP 시스템즈',
            period: '2023.08 - 2025.01',
            position: '대리',
            projects: [
                {
                    name: '우리은행 New원뱅킹 재구축사업',
                    details: [
                        'Next.JS 기반 우리은행 New원뱅킹 재구축사업 프로젝트',
                        ' 전담직원직원관리기능 개발',
                        ' 쿠폰관리기능 개발',
                        ' 꿀머니당첨내역관리기능 개발',
                        ' 계좌관리기능 개발',
                        ' 기부처관리기능 개발',
                        ' 배너관리기능 개발',
                        ' 전담직원추천컨텐츠관리기능 개발',
                    ],
                },
                {
                    name: '서울시 청소년협회 시설관리 시스템 유지보수',
                    details: [
                        ' 로그인 사용자 정보 조회 기능 유지보수',
                        ' 시설관리 상세페이지 조회, 수정 기능 유지보수',
                        ' 시설관리 목록조회 및 검색 기능 유지보수',
                        ' 시설관리 상세정보 엑셀 다운로드기능 개발',
                    ],
                },
                {
                    name: '우리은행 UIMANAGER 시스템 개발',
                    details: [
                        ' 사원정보 생성,수정,목록조회,검색,상세조회,삭제 기능 개발',
                        ' 소스정보 신규입력 파일업로드 기능 개발',
                        ' 소스정보 수정기능 개발',
                    ],
                },
            ],
        },
        // ESP 시스템즈

        // 주식회사밸류링크유
        {
            company: '주식회사밸류링크유',
            period: '2023.03 - 2023.07',
            position: '사원',
            projects: [
                {
                    name: 'Valuelinku 고객 해운사 Booking System 개발',
                    details: [
                        ' 해운사 선사코드 기준 입항내역관리기능 개발',
                        ' 선사입항상세내역관리기능 개발 ',
                    ],
                },
                {
                    name: 'Valuelinku 주문정보조회 API 개발',
                    details: [
                        ' 네이버 API기반 고객사 회원의 주문내역 조회 API 개발',
                        ' 네이버 API기반 고객사 회원의 반품신청내역 조회 API 개발',
                    ],
                },
            ],
        },
        // 주식회사밸류링크유

        // 와이즈아이씨티 
        {
            company: '와이즈아이씨티',
            period: '2022.02 - 2023.03',
            position: '사원',
            projects: [
                {
                    name: '남성해운 Booking System 개발',
                    details: [
                        ' 남성해운 공지 게시판 게시글 작성, 수정, 삭제, 조회 개발',
                        ' 공지 게시판 파일업로드기능 개발',
                        ' 회원 구분코드 기준 해운사별 게시물 조회기능 분기처리 기능 개발',
                        ' 부킹 신청 기능 및 무작위 배정 기능, 배정 담당자 외부 이메일 전송 기능 개발',
                    ],
                },
                {
                    name: '현대캐피탈 업무시스템 개발 및 시스템 유지보수',
                    details: [
                        ' 업로드 파일 확장자 검토 기능 개발',
                        ' 업로드 파일 민감성 데이터 검토 기능 개발',
                        ' 상세페이지 분기처리기능 유지보수 시행',
                    ],
                },
            ],
        },
        // 와이즈아이씨티

        // 주식회사 넥스트아이씨티 
        {
            company: '주식회사 넥스트아이씨티',
            period: '2020.12 - 2021.07',
            position: '사원',
            projects: [
                {
                    name: '경찰청 업무시스템 KICS 유지보수',
                    details: [
                        ' 촉법소년 정보 조회기능 유지보수',
                        ' 수감자 정보 조회 기능, 입출감 기능 수정 및 개발',
                        ' 수감자 정보 입력, 수정 기능 개발',
                    ],
                },
            ],
        },
        // 주식회사 넥스트아이씨티
    ];

    return (
        <div className={st.mainWrap}>
            <Header />
            <div className={st.banner_content}>
                <h1 className={st.name}>민창준 경력 소개</h1>
                <p className={st.name}>소프트웨어 개발자</p>
                <div className={st.page_wrapper}>
                    {/* 개인 정보 섹션 */}
                    <div className={st.section}>
                        <h2>개인 정보</h2>
                        <p><strong>이름:</strong> {personalInfo.name}</p>
                        <p><strong>주소:</strong> {personalInfo.address}</p>
                        <p><strong>이메일:</strong> {personalInfo.email}</p>
                        <p><strong>전화:</strong> {personalInfo.phone}</p>
                    </div>
                    {/* 기술 섹션 */}
                    <div className={st.skillSection}>
                        <h2>기술</h2>
                        <ul>
                            {skills.map((skill, idx) => (
                                <li key={idx}>{skill}</li>
                            ))}
                        </ul>
                    </div>
                    {/* 경력 섹션 */}
                    <div className={st.section}>
                        <h2>경력</h2>
                        {career.map((job, idx) => (
                            <div key={idx} className={st.career_item}>
                                <h3>{job.company} ({job.period})</h3>
                                {/* <p><strong>직책:</strong> {job.position}</p> */}
                                {job.projects.map((project, pIdx) => (
                                    <div key={pIdx}>
                                        <h4>{project.name}</h4>
                                        <ul>
                                            {project.details.map((detail, dIdx) => (
                                                <li key={dIdx}>{detail}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>

                    {/* 학력 섹션 */}
                    <div className={st.section}>
                        <h2>학력</h2>
                        <ul>
                            {education.map((edu, idx) => (
                                <li key={idx}>
                                    <strong>{edu.school}</strong> ({edu.major}, {edu.graduation})
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* 자격증 섹션 */}
                    <div className={st.section}>
                        <h2>자격증</h2>
                        <ul>
                            {certificates.map((cert, idx) => (
                                <li key={idx}>
                                    <strong>{cert.name}</strong> ({cert.acquired})
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Intro;
