// -------------------------------------------------
// reactmoduels import
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { changeName } from "../../store.js"
import axios from 'axios';

// style
import st from '../../style/common/header.module.css'

// mamaterial ui
import { Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useEffect, useState } from 'react';
// import { useScrollToTop } from '../../hooks/useScrollToTop';



function Header() {
  const lg = console.log;
  const dispatch = useDispatch(); // redux 의 state변경함수 사용하기위해 선언
  // window.scrollTo(0, 0);
  // useScrollToTop();

  const [state, setState] = useState({
    idx: '',
    id: '',
    phnNum: '',
    compNm: '',
    compLoc: '',
  });

  const {
    idx,
    id,
    phnNum,
    compNm,
    compLoc,
  } = state;

  // redux 로 가져오는 state 는 함수 안에서 사용할 수 없다. (아래 예시)
  // const getReduxData = () =>{
  //   let a = useSelector((state) => { return state })
  //   console.log('a == ', a)  
  // }
  //
  // let a = useSelector((state) => { return state })
  // console.log('a == ', a)



  const navigate = useNavigate();

  const goHome = () => {
    navigate('/');
  }

  const goBoard = () => {
    navigate('/board/list');
  }

  const goIntro = () => {
    navigate('/intro');
  }

  const goSignIn = () => {
    navigate('/auth/signin');
  }

  const test = () => {
    dispatch(changeName());
  }

  // 세션존재 확인
  const getSessionData = () => {
    axios.post(`http://${process.env.REACT_APP_API_URL}/auth/checkLogin`).then((res) => {
      const resData = res.data;
      // lg('getSessionData 통신성공 = ', resData)
      if (resData && idx == '') {
        lg('헤더 세션데이터 ==  ', resData);
        setState(prevState => ({
          ...prevState,
          idx: resData.idx,
          id: resData.id,
          idx: resData.ceoNm,
          phnNum: resData.phnNum,
          compNm: resData.compNm,
          compLoc: resData.compLoc,
        }));
      }
    })
      .catch((err) => {
        lg('세션확인 실패')
      })
  }

  const doLogout = () => {
    axios.post(`http://${process.env.REACT_APP_API_URL}/auth/logout`).then((res) => {
      const resData = res.data;
      lg('getSessionData 통신성공 = ', resData)
      alert(res.data?.msg);
      setState({
        idx: '',
        id: '',
        phnNum: '',
        compNm: '',
        compLoc: '',
      });
      navigate('/auth/signin');
    })
      .catch((err) => {
        lg('로그아웃 실패')
      })
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    getSessionData();
  }, [])

  return (
    <>
      <div className={st.wrap}>
        <div className={st.boxLeft}>
          <div className={st.logo}>
            <h1><a onClick={goHome}>NodeWorld</a></h1>
          </div>
        </div>


        <div className={st.boxRight}>
          <div className={st.menu}>
            <a onClick={goHome}>Home</a>
            <a onClick={goIntro}>Intro</a>
            <a onClick={goBoard}>Comment</a>
            {
              idx == '' ? <a onClick={goSignIn}>SignIn</a> : <a onClick={doLogout}>Logout</a>
            }

            {/* <a onClick={test}>test</a> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
