import { React, useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';

// CSS
import st from '../../style/board/detail.module.css';

// 컴포넌트
import Header from '../common/Header';
import Footer from '../common/Footer';


const Detail = () => {
    const lg = console.log;
    const usp = useParams(); // /board/:idx와 동일한 변수명으로 꺼내기 가능
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = location.state?.searchParams || '';

    // 상태
    const [state, setState] = useState({
        idx: usp?.idx,
        isDisabled: false,
        detailData: {
            idx: '',
            title: '',
            content: '',
            regdt: dayjs().format('YYYY-MM-DD'),
            regid: '',
            id: '',
        },
        sessionData: {
            idx: '',
            id: '',
            idx: '',
            phnNum: '',
            compNm: '',
            compLoc: '',
        },
    });

    const [selectedFile, setSelectedFile] = useState(null);  // 추가: 파일 상태

    // 상태 추출
    const { idx, isDisabled, detailData, sessionData } = state;


    // 상태 변경 함수들
    const onChangeTitle = (e) => {
        setState(prevState => ({
            ...prevState,
            detailData: {
                ...detailData,
                title: e.target.value
            }
        }));
    };

    // 내용 변경
    const onChangeContent = (e) => {
        setState(prevState => ({
            ...prevState,
            detailData: {
                ...detailData,
                content: e.target.value
            }
        }));
    };


    // 기능 함수
    const getBrdDtl = () => {
        const params = {
            idx
        }

        axios.post(`http://${process.env.REACT_APP_API_URL}/board/getBrdDtl`, params).then((res) => {
            lg(res.data[0])
            setState((prevState) => ({
                ...prevState,
                detailData: res.data[0]
            }));
        }).catch(() => {
            lg('실패함')
        })
    }

    const setBtnDisabled = (flag) => {
        setState(prevState => ({
            ...prevState,
            isDisabled: flag
        }));
    }

    // 저장
    const postSave = async () => {
        lg('postSave')
        setBtnDisabled(true);

        if (detailData.title == '') {
            alert('제목을 입력하세요');
            setBtnDisabled(false)
            return;
        }

        if (detailData.content == '') {
            alert('내용을 입력하세요');
            setBtnDisabled(false)
            return;
        }



        let url = 'insertBrd';

        let params = {
            title: detailData.title,
            content: detailData.content,
            // regid: detailData?.regid || '1'
        }
        if (detailData.idx) {
            // 수정
            lg('수정시작')
            url = 'updateBrd';
            params = {
                title: detailData.title,
                content: detailData.content,
                regid: sessionData?.idx,
                idx: detailData.idx,
            }
        }
        await axios.post(`http://${process.env.REACT_APP_API_URL}/board/${url}`, params).then((res) => {
            lg('성공 == ', res.data?.msg)
            alert(res.data?.msg)
            navigate('/board/list');
        }).catch((err) => {
            lg('실패함')
            alert(err.response?.data?.msg);
            setBtnDisabled(false)
        })
    }

    // 삭제
    const postDelete = async () => {
        lg('postDelete', idx)
        const params = {
            idx
        }
        await axios.post(`http://${process.env.REACT_APP_API_URL}/board/deleteBrd`, params).then((res) => {
            alert(res.data?.msg)
            navigate('/board/list');
        }).catch((err) => {
            lg('삭제 실패')
            alert(err.response?.data?.msg);
        })
    }

    // 취소
    const onCancel = () => {
        navigate('/board/list', {
            state: {
                searchParams
            }
        });
    };

    // 목록
    const goToList = () => {
        navigate('/board/list', {
            state: {
                searchParams
            }
        });
    };

    // 세션존재 확인
    const getSessionData = () => {
        axios.post(`http://${process.env.REACT_APP_API_URL}/auth/checkLogin`).then((res) => {
            const resData = res.data;
            lg('getSessionData 통신성공 = ', resData)
            if (resData) {
                lg('resData?.name ==  ', resData);
                setState(prevState => ({
                    ...prevState,
                    sessionData: {
                        idx: resData.idx,
                        id: resData.id,
                        phnNum: resData.phnNum,
                        compNm: resData.compNm,
                        compLoc: resData.compLoc,
                    }
                }));
            }
        })
            .catch((err) => {
                lg('세션확인 실패')
            })
    }

    useEffect(() => {
        if (idx) {
            getBrdDtl();
        }
        getSessionData();
    }, [idx])

    return (
        <>
            <Header />
            <div className={st.mainWrap}>
                <div className={st.banner_content}>
                    <div className={st.form_section}>
                        <h2 className={st.form_title}>Comment 작성</h2>
                        <div className={st.form}>
                            {/* 작성자와 등록일자 추가 */}
                            <div className={st.info_group}>
                                {
                                    detailData?.idx ?
                                        (
                                            <div className={st.info_item}>
                                                <label>게시번호 :</label>
                                                <span>{detailData?.idx}</span>
                                            </div>
                                        ) : ('')
                                }
                                {
                                    detailData.idx == '' && sessionData.id == '' ? (
                                        ''
                                    ) : (
                                        <div className={st.info_item}>
                                            <label>작성자:</label>
                                            <span>{detailData?.id ? detailData?.id : sessionData?.id}</span>
                                        </div>
                                    )
                                }


                                <div className={st.info_item}>
                                    <label>등록일자 :</label>
                                    <span>{dayjs(detailData?.regdt).format('YYYY년 MM월 DD일')}</span>
                                </div>
                            </div>

                            <div className={st.input_group}>
                                <label htmlFor="title">제목</label>
                                <input
                                    type="text"
                                    id="title"
                                    name="title"
                                    value={detailData?.title}
                                    onChange={onChangeTitle}
                                    required
                                    className={st.input_field}
                                    placeholder="제목을 입력하세요"
                                />
                            </div>
                            <div className={st.input_group}>
                                <label htmlFor="content">내용</label>
                                <textarea
                                    id="content"
                                    name="content"
                                    value={detailData?.content}
                                    onChange={onChangeContent}
                                    required
                                    className={st.textarea_field}
                                    rows="15"
                                    placeholder="내용을 입력하세요"
                                />
                            </div>
                            <div className={st.buttons_container}>
                                <button className={st.list_button} onClick={goToList}>
                                    목록
                                </button>
                                <div className={st.right_buttons}>

                                    {sessionData.id != '' ? (
                                        // 로그인 했을 때 버튼 보여주기    
                                        <>
                                            {
                                                // 로그인한 사람 본인의 게시물일 때 수정가능
                                                sessionData.idx != '' && sessionData.idx == detailData.regid ? (
                                                    <>
                                                        <button className={st.submit_button} onClick={postSave} disabled={isDisabled}>
                                                            수정하기
                                                        </button>
                                                        <button className={st.cancel_button} onClick={postDelete} disabled={isDisabled}>
                                                            삭제
                                                        </button>
                                                    </>
                                                ) :

                                                // 로그인 후 신규등록
                                                    detailData.idx == '' ?
                                                        (
                                                            <>
                                                                <button className={st.submit_button} onClick={postSave} disabled={isDisabled}>
                                                                    등록하기
                                                                </button>
                                                            </>
                                                        ) :
                                                        ''
                                            }

                                        </>
                                    ) : ('')
                                    }

                                    {/* <button
                                        type="button"
                                        className={st.cancel_button}
                                        onClick={() => { lg('state == ', state) }}
                                    >
                                        state
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
        </>
    );
}

export default Detail;